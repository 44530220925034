import React from "react"
import DoubleImgCard from "./DoubleImgCard"

const DoubleImgBlock = ({ cards, pageSlug }) => {
  return (
    <>
      {cards.map((data, index) => {
        const reverse = index % 2
        const hasBackground = (index - 1) % 3 === 0

        return (
          <div key={index}>
            <DoubleImgCard
              {...data}
              pageSlug={pageSlug}
              background={hasBackground ? 1 : 0}
              reverse={reverse ? 1 : 0}
            />
          </div>
        )
      })}
    </>
  )
}

export default DoubleImgBlock
