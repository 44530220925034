import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import UpNext from "../components/Core/UpNext"
import DoubleImgBlock from "../components/Portfolio/DoubleImgBlock"
import PageTitle from "../components/Portfolio/PageTitle"
import { graphql } from "gatsby"

const PortfolioTemplate = ({ data, location }) => {
  const path = location.pathname
  const { page } = data

  const { heading, cards, upNext, slug } = page

  return (
    <Layout>
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        path={path}
      />
      <PageTitle title={heading} />
      <DoubleImgBlock cards={cards} pageSlug={slug} />
      <UpNext
        title={upNext.name}
        link={upNext.ctaLink}
        text={upNext.ctaLabel}
        image={upNext.image}
        alt={upNext.image.title || ""}
      />
    </Layout>
  )
}

export default PortfolioTemplate

export const pageQuery = graphql`
  query PortfolioQuery($slug: String!) {
    page: contentfulPortfolioPage(slug: { eq: $slug }) {
      slug
      metaTitle
      metaDescription

      heading

      cards {
        category
        title
        content {
          json
        }
        images {
          fluid(quality: 100, maxWidth: 992) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }

      upNext {
        image {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        ctaLabel
        ctaLink
        name
      }
    }
  }
`
