import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import BackgroundArrow from "../../resources/img/projects/combined_shape_sm.png"
import Img from "gatsby-image"

const Title = styled.h2`
  ${fonts.avantGardeBold};
  color: ${colors.blue};
  text-transform: uppercase;
  font-size: 36px;
  padding: 40px 0;
  @media (min-width: ${breakpoints.md}) {
    font-size: 48px;
    line-height: auto;
    max-width: 650px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 54px;
    line-height: auto;
    max-width: 900px;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 60px;
    line-height: auto;
    max-width: 1000px;
  }
`

const ContainerStyled = styled.div`
  padding: 20px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
    padding-left: 20px;
    background: ${({ bg }) => bg && `url(${BackgroundArrow}) no-repeat;`};
    background-position: center right;
    background-size: auto 100%;
    height: 340px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0;
    padding-left: 20px;
    height: 452px;
  }
`

const TitleContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  height: inherit;
  align-items: flex-end;
`

const PageTitle = ({ title }) => {
  return (
    <ContainerStyled bg>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
    </ContainerStyled>
  )
}

export default PageTitle
