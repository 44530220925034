import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Col, Container, Row } from "styled-bootstrap-grid"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ColStyled = styled(Col)`
  padding: 20px;
  display: grid;
  place-content: center;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    padding: 20px 60px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 30px;

  }
`
const Category = styled.h3`
  ${fonts.avantGardeMedium};
  color: ${({ white }) => (white ? `${colors.white}` : `${colors.blue}`)};
  text-transform: uppercase;
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
  @media (min-width: ${breakpoints.lg}) {
    padding-bottom: 30px;
    max-width: 480px;
  }
`

const Title = styled.h2`
  ${fonts.avantGardeMedium};
  color: ${({ white }) => (white ? `${colors.white}` : `${colors.blue}`)};
  font-size: 32px;
  line-height: auto;
  margin-bottom: 10px;
  span {
    vertical-align: super;
    font-size: 14px;
  }
  max-width: 620px;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 5px;
    max-width: 480px;
  }
`

const ImageContainer = styled.div`
  min-width: 260px;
  min-height: 250px;
  @media (min-width: ${breakpoints.md}) {
    min-width: 360px;
    min-height:400px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-width: 410px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-width: 496px;

  }
`
const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledImage2 = styled(Img)`
  width: 97%;
  height: 97%;
  object-fit: cover;
`

const TopImageContainer = styled.div`
  position: absolute;
  z-index: 10;
  min-width: 126px;
  top: 180px;
  left: 20px;
  top: ${({ position }) => (position ? 60 : 150)}px;
  left: ${({ position }) => (position ? 230 : 20)}px;

  @media (min-width: ${breakpoints.md}) {
    top: ${({ position }) => (position ? 80 : 200)}px;
    left: ${({ position }) => (position ? 305 : 0)}px;
    min-width: 160px;
  }

  @media (min-width: ${breakpoints.lg}) {
    top: ${({ position }) => (position ? 120 : 260)}px;
    left: ${({ position }) => (position ? 320 : 0)}px;
  }
  
  @media (min-width: ${breakpoints.xl}) {
    top: ${({ position }) => (position ? 100 : 280)}px;
    left: ${({ position }) => (position ? 460 : 10)}px;
    min-width: 190px;
  }
`
const ImageBox = styled.div`
  padding: 20px;
  max-width: 390px;
  margin: 0 auto;
  position: relative;
  display: grid;
  place-content: ${({ position }) => (position ? "start" : "end")};

  @media (min-width: ${breakpoints.md}) {
    max-width: 500px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 50px;
    max-width: 560px;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 665px;
  }

`

const RowStyled = styled(Row)`
  padding: 30px 0 10px;
  max-width: 1440px;
  margin: 0 auto;
  @media (min-width: ${breakpoints.sm}) {
    margin: ${({ margin }) => (margin ? `110px auto 0` : `0 auto`)};
  }
  @media (min-width: ${breakpoints.md}) {
    margin: 0 auto;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: ${({ margin }) => (margin ? `60px auto 0` : `0 auto`)};
  }
`
const ContainerStyled = styled(Container)`
  position: relative;
`

const Background = styled(Img)`
  position: absolute !important;
  height: 1160px;
  width: 100%;
  z-index: -1;

  @media (min-width: ${breakpoints.sm}) {
    height: 990px;
  }

  @media (min-width: ${breakpoints.md}) {
    height: 1400px;
    width: 100%;
    top: 40px;
    img {
      object-fit: unset !important;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    height: ${({ bigHeight }) => (bigHeight ? `1050px` : `920px`)};
    top: -150px;
  }
`

const StyledContent = styled.div`
  ${fonts.avantGardeMedium};
  color: ${({ background }) => (background ? `${colors.white}` : `${colors.grey}`)};
  font-size: 16px;
  line-height: 24px;
  max-width: 620px;
  padding: 0 20px;
  margin-top: 30px;

  p {
    margin-bottom: 20px;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    li {
      color: ${({ background }) => (background ? `${colors.white}` : `${colors.grey}`)};
      list-style: disc;
      padding: 10px 0;
      margin-left: 40px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: ${({background}) => (background ? "240px" : "160px")};

    max-width: 520px;
  }
`

const DoubleImgCard = (props) => {
  const {
    category,
    title,
    content,
    reverse,
    isLast,
    background,
    images,
    pageSlug
  } = props

  const { backgroundImage } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(relativePath: { eq: "projects/background.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 992) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <ContainerStyled fluid>
      {background ? (<Background fluid={backgroundImage.childImageSharp.fluid} bigHeight={pageSlug === "brands" ? 1 : 0} alt=""/>) : null}
      <RowStyled margin={isLast}>
        <Col lg={6} sm={12}>
          <ImageBox position={reverse}>
            {images.map((image, index) => {
              return index === 0 ? (
                <ImageContainer>
                  <StyledImage2 fluid={image.fluid} alt={image.title || ""} />
                </ImageContainer>
              ) : (
                <TopImageContainer position={reverse}>
                  <StyledImage fluid={image.fluid} alt={image.title || ""} />
                </TopImageContainer>
              )
            })}
          </ImageBox>
        </Col>
        <ColStyled lg={6} sm={12} lgOrder={reverse ? "first" : "last"}>
          <Category white={background ? 1 : 0}>{category}</Category>
          <Title
            white={background ? 1 : 0}
            dangerouslySetInnerHTML={{
              __html: title.replace("®", "<span>®</span>"),
            }}
          />

          <StyledContent background={background ? 1 : 0}>
            {documentToReactComponents(content.json)}
          </StyledContent>
        </ColStyled>
      </RowStyled>
    </ContainerStyled>
  )
}

export default DoubleImgCard
